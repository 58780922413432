const FORM_SELECTOR = 'js-cross-sell-add-to-cart-form';
const REF_SELECTOR = 'input[name="reflm"]';
const QUANTITY_SELECTOR = 'input[name="quantity"]';
const OFFER_SELECTOR = 'input[name="offerId"]';
const CONTEXT_SELECTOR = 'input[name="contextCode"]';
class AddToCartForTopProducts {
  constructor(element = null, cartUpdateEvent = null) {
    try {
      if (element === null) throw 'element params is mandatory';
      if (cartUpdateEvent === null) throw 'cartUpdateEvent params is mandatory';
      this.cartUpdateEvent = cartUpdateEvent;
      this.forms = element.querySelectorAll(`.${FORM_SELECTOR}`);
    } catch (e) {
      throw `error : ${e}`;
    }
  }
  init() {
    if (this.forms) addSubmitEventsForms.call(this);
  }
}
function addSubmitEventsForms() {
  this.forms.forEach(form => {
    form.addEventListener('submit', dispatchEvent.bind(this, form));
  });
}
function dispatchEvent(form, event) {
  event.preventDefault();
  const reflm = form.querySelector(REF_SELECTOR).value;
  const quantity = form.querySelector(QUANTITY_SELECTOR).value;
  const offerId = form.querySelector(OFFER_SELECTOR) ? form.querySelector(OFFER_SELECTOR).value : null;
  const contextCode = form.querySelector(CONTEXT_SELECTOR) ? form.querySelector(CONTEXT_SELECTOR).value : null;
  this.cartUpdateEvent({
    listProducts: [{
      reflm,
      quantity,
      offerId,
      contextCode,
      reflmAvailability: null
    }]
  });
}
export default AddToCartForTopProducts;